


































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetSubprefeituras,
  dispatchUpdateSubprefeitura,
} from '@/store/subprefeitura/actions';
import { consultarSubprefeitura } from '@/store/subprefeitura/getters';
import { ISubprefeituraUpdate } from '@/interfaces/subprefeitura';

@Component
export default class AlterarSubprefeitura extends Vue {
  public valid = true;
  public nome: string = '';

  public async mounted() {
    await dispatchGetSubprefeituras(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.subprefeitura) {
      this.nome = this.subprefeitura.nome;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const subprefeituraAtualizada: ISubprefeituraUpdate = {
        nome: this.nome,
      };

      const atualizou = await dispatchUpdateSubprefeitura(this.$store, {
        id: this.subprefeitura!.id,
        subprefeitura: subprefeituraAtualizada,
      });
      if (atualizou) {
        this.$router.push('/main/subprefeituras/listar');
      }
    }
  }

  get subprefeitura() {
    return consultarSubprefeitura(this.$store)(+this.$router.currentRoute.params.id);
  }
}
